@import "../../styles/main";

.form {
  width: 410px;
  background: #fff;
  padding: 40px 30px;
  border: 1px solid #eee;
}

.wrap {
  height: 100vh;
  background: #fafafa;
}
