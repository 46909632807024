$header-height: 46px;

$color: #ededed;
$gutter-size: 10px;

//
$header-height: 66px;
$main-height: calc(100vh - #{$header-height});
$footer-height: 100px;
$min-width: 1140px;
$page-padding: 10px;
$gutter-size: 10px;
$border-radius: 4px;

// colors
$shade-color: #cecece;
$primary-color: #0070b9;
$secondary-color: #999;
$border-color: #e6eaf0;
$white-color: #fff;
$bg-color: #f5f7fa;
//


$primary-text-color: #333;
$error-color: #d41c1b;
$danger-color: #d41c1b;
$warning-color: #ff9500;
$info-color: #69a4cd;
$success-color: #07b424;

$red-color: #fa5252;
$green-color: #12b886;
